/*----------------------------------*\
/*----------------------------------*\
     CLEAR SPRING MAIN UI SCRIPT
/*----------------------------------*\
\*----------------------------------*/

/*------------------------------------*\
  #Ready
\*------------------------------------*/

var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

if (Modernizr.flexbox && Modernizr.flexwrap) {
  // Modern Flexbox with `flex-wrap` is supported
} else {
  flexibility(document.documentElement);
}
if (!isMobile) {
  $(window).on('scroll', function () {
    var distance = $(document).scrollTop();

    if (distance > 0) {
      $('.header__main, main[role="main"]').attr('is-floating', 'true');
    }
    else {
      $('.header__main,  main[role="main"]').attr('is-floating', 'false');
    }
  });
}
else {
  var didScroll = false;
  var lastScrollTopMobile = 0;
  var delta = 5;
  var navbarHeight = $('header').outerHeight();

  $('header').addClass('nav-down');

  $(window).scroll(function (event) {
    didScroll = true;
  });

  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    var st = $(this).scrollTop();

    if (Math.abs(lastScrollTopMobile - st) <= delta)
      return;

    if (st > lastScrollTopMobile && st > navbarHeight) {
      $('header').removeClass('nav-down').addClass('nav-up');
    } else {
      if (st + $(window).height() < $(document).height()) {
        $('header').removeClass('nav-up').addClass('nav-down');
      }
    }

    lastScrollTopMobile = st;
  }
}

// See More / Less
$('.button__see-more').click(function (e) {
  e.preventDefault();
  $(this).siblings('.slide-down--mobile').slideToggle(function () {
    var text = $(this).is(':hidden') ? 'See More +' : 'See Less -';
    $('.button__see-more').html(text);
  });
});


// Scroll-down to section
$('.section__anchor').on('click', function (event) {
  var section = $(this).attr('href');
  var target = window.location.hash;
  event.preventDefault();
  $('html, body').animate({
    scrollTop: $(section).offset().top - 50
  });
  if (target) {
    window.location.hash = '';
  }
});

// Scroll Detection to start hero Animation
var lastScrollTop = 0;
$(window).on('scroll', function () {
  var auxCapitalized = $('#capitalized').offset().top;
  var auxServices = $('#services').offset().top;
  var auxAssociates = $('#associates').offset().top;
  var auxSponsors = $('#sponsors').offset().top;
  var scrollTop = $(this).scrollTop();
  var sectionCapitalized = auxCapitalized - auxCapitalized / 8;
  var sectionServices = auxServices - auxServices / 4;
  var sectionAssociates = auxAssociates - auxAssociates / 8;
  var sectionSponsors = auxSponsors - auxSponsors / 8;
  if (scrollTop > sectionCapitalized) {
    $('#capitalized .block__image img').addClass('fade-in--up');
  }
  if (scrollTop > sectionServices) {
    $('#services .list__item').addClass('fade-in--up');
  }
  if (scrollTop > sectionAssociates) {
    $('#associates .list__item').addClass('fade-in--up');
  }
  if (scrollTop > sectionSponsors) {
    $('#sponsors .list__item').addClass('fade-in--up');
  }
  lastScrollTop = scrollTop;
});


// Form validations
var error = false;

function formValidation(formID) {
  errorsArray = []
  var emailRegex = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
  var textError = 'Please fill in this value';
  var emailError = 'Please provide your email address';
  var numberError = 'Please provide your number';
  var nameError = 'Please give us your name';
  var messageError = 'Please tell us what you are writing about';
  var roleError = 'Please tell us your role';
  var topicError = 'Please tell us the topic of your inquiry';
  var coverageError = 'Please tell us the coverage concerning your inquiry';
  var stateError = 'Please tell us the state where coverage is applicable';

  $('.required', $(formID)).each(function () {
    var elemValue = $(this).val();
    var parentInput = $(this).parent('.input__container');

    if ($(this).hasClass('field-text')) {
      if ((elemValue.length == 0) || (elemValue.length == null) || (elemValue == ' ')) {

        parentInput.find('em').html(messageError);
        parentInput.addClass('input--has-errors');
        error = true;
      } else {
        parentInput.removeClass('input--has-errors');
        error = false;
      }
      errorsArray.push(error);
    }
    if ($(this).hasClass('field-email')) {
      if ((elemValue.length == 0) || (elemValue.length == null) || (elemValue == ' ')) {
        parentInput.find('em').html(emailError);
        parentInput.addClass('input--has-errors');
        error = true;
      } else if (!emailRegex.test(elemValue)) {
        parentInput.find('em').html(emailError);
        parentInput.addClass('input--has-errors');
        error = true;
      } else {
        parentInput.removeClass('input--has-errors');
        error = false;
      }
      errorsArray.push(error);
    }
    if ($(this).hasClass('field-name')) {
      if ((elemValue.length == 0) || (elemValue.length == null) || (elemValue == ' ')) {

        parentInput.find('em').html(nameError);
        parentInput.addClass('input--has-errors');
        error = true;
      } else {
        parentInput.removeClass('input--has-errors');
        error = false;
      }
      errorsArray.push(error);
    }
    if ($(this).hasClass('field-number')) {
      if ((elemValue.length == 0) || (elemValue.length == null) || (elemValue == ' ')) {

        parentInput.find('em').html(numberError);
        parentInput.addClass('input--has-errors');
        error = true;
      } else {
        parentInput.removeClass('input--has-errors');
        error = false;
      }
      errorsArray.push(error);
    }
    if ($(this).hasClass('field-subject')) {
      if ((elemValue.length == 0) || (elemValue.length == null) || (elemValue == ' ')) {

        parentInput.find('em').html(textError);
        parentInput.addClass('input--has-errors');
        error = true;
      } else {
        parentInput.removeClass('input--has-errors');
        error = false;
      }
      errorsArray.push(error);
    }
    if ($(this).hasClass('field-role')) {
      if ((elemValue != null && elemValue.length < 1) || elemValue == null || elemValue == '' || elemValue == ' ') {
      parentInput.find('em').html(roleError);
      parentInput.addClass('input--has-errors');
      error = true;
      } else {
      parentInput.removeClass('input--has-errors');
      error = false;
      }
      errorsArray.push(error);
    }
    if ($(this).hasClass('field-topic')) {
      if ((elemValue != null && elemValue.length < 1) || elemValue == null || elemValue == '' || elemValue == ' ') {

        parentInput.find('em').html(topicError);
        parentInput.addClass('input--has-errors');
        error = true;
      } else {
        parentInput.removeClass('input--has-errors');
        error = false;
      }
      errorsArray.push(error);
    }
    if ($(this).hasClass('field-coverage')) {
      if ((elemValue != null && elemValue.length < 1) || elemValue == null || elemValue == '' || elemValue == ' ') {

        parentInput.find('em').html(coverageError);
        parentInput.addClass('input--has-errors');
        error = true;
      } else {
        parentInput.removeClass('input--has-errors');
        error = false;
      }
      errorsArray.push(error);
    }
    if ($(this).hasClass('field-state')) {
      if ((elemValue != null && elemValue.length < 1) || elemValue == null || elemValue == '' || elemValue == ' ') {

        parentInput.find('em').html(stateError);
        parentInput.addClass('input--has-errors');
        error = true;
      } else {
        parentInput.removeClass('input--has-errors');
        error = false;
      }
      errorsArray.push(error);
    }
  });

  if ($.inArray(true, errorsArray) == -1) {
    return true;
  } else {
    return false;
  }
}

function sendMail(formID) {
  var evaluateValid = formValidation(formID);

  if (!evaluateValid) {
    $(formID).removeClass('form--is-sending form--is-sent').addClass('form--is-error');
    $('#error-message').text('Please complete required fields')
    setTimeout(function () {
      $(formID).removeClass('form--is-error').addClass('form--had-errors');
    }, 3000);
    return false;
  } else {
    var m_data = {};
    m_data["siteKey"] = "pclearspringpcContact";
    m_data["firstName"] = $('#firstName').val();
    m_data["lastName"] = $('#lastName').val();
    m_data["emailAddress"] = $('#emailAddress').val();
    m_data["phoneNumber"] = $('#phoneNumber').val();
    m_data["policyNumber"] = $('#csPolicyNumber').val();
    m_data["claimNumber"] = $('#csClaimNumber').val();
    m_data["claimNumber"] = $('#csClaimNumber').val();
    m_data["state"] = $('#contactState').val();

    // Validate conditional options for custom input on dropdown
    if ($('#otherCustomRole').val() == '') { m_data['contactRole'] = $('#contactRole').val(); } else { m_data['contactRole'] = $('#otherCustomRole').val(); }
    if ($('#otherCustomCoverage').val() == '') { m_data['contactCoverage'] = $('#contactCoverage').val(); } else { m_data['contactCoverage'] = $('#otherCustomCoverage').val(); }
    if ($('#otherCustomTopic').val() == '') { m_data['contactTopic'] = $('#contactTopic').val(); } else { m_data['contactTopic'] = $('#otherCustomTopic').val(); }

    m_data['message'] = $('#message').val();
    //m_data.append('recaptchaResponse', grecaptcha.getResponse());

    $(formID).addClass('form--is-sending');

    $.ajax({
      type: 'POST',
      url: $(formID).attr('action'),
      contentType: "application/json",
      processData: false,
      data: JSON.stringify(m_data),
      timeout: 8000,
      success: function (response) {
        $(formID).removeClass('form--is-sending').removeClass('form--had-errors').addClass('form--is-sent');
      },
      error: function (result) {
        $(formID).removeClass('form--is-sending form--is-sent').addClass('form--is-error');

        setTimeout(function () {
          $(formID).removeClass('form--is-error').addClass('form--had-errors');
        }, 3000);
      }
    });
  }
}

$('#buttonForm').on('click', function (event) {
  event.preventDefault();
  sendMail('#form');
});

var urlParams = new URLSearchParams(window.location.search);
var redirect = urlParams.get('redirect');
if(redirect == 1)
{
    MicroModal.show('site_alert');
}

